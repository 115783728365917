<template>
  <div
    class="flex justify-between w-full my-1-5 sm:my-2-5 p-2-5 sm:px-6 border-gray-200 rounded"
    :class="[
      selectedFile || currentFile ? 'border' : 'border-2 border-dashed sm:py-10'
    ]"
  >
    <div class="flex justify-start items-center">
      <icon-base height="40" width="40" icon="file" />
      <span
        :class="`pl-1-5 sm:pl-5 text-tiny-xs sm:text-sm text-gray-500 font-medium ${
          currentFile && currentFile.original_name ? 'cursor-pointer' : ''
        }`"
        @click="handleW9Download"
      >
        {{
          selectedFile
            ? selectedFile.name
            : currentFile
              ? currentFile.original_name
              : uploadDescription
        }}
      </span>
    </div>
    <div
      class="justify-end place-self-center font-medium text-xxs sm:text-sm text-primary cursor-pointer"
    >
      <label class="cursor-pointer">
        {{ selectedFile || currentFile ? "Upload new" : btnLabel }}
        <input
          id="file-upload"
          name="file-upload"
          type="file"
          class="sr-only"
          :value="fileName"
          :accept="accept"
          @input="emitFileUpdated"
        />
      </label>
    </div>
  </div>
</template>
<script type="text/javascript">
import { ref } from "vue";
import { downloadFile } from "@/helpers/files";

export default {
  props: {
    btnLabel: {
      type: String,
      default: "Upload"
    },
    uploadDescription: {
      type: String,
      default: null
    },
    currentFile: {
      type: Object,
      default: null
    },
    accept: {
      type: String,
      default: "*"
    }
  },
  setup(props, { emit }) {
    const selectedFile = ref(null);

    const handleW9Download = async () => {
      if (props.currentFile && props.currentFile.original_name) {
        await downloadFile(
          props.currentFile.file_url,
          {},
          props.currentFile.original_name
        );
      }
    };

    return {
      selectedFile,
      handleW9Download,
      emitFileUpdated: (event) => {
        selectedFile.value = event.target.files[0];
        emit("fileUploaded", event);
      }
    };
  }
};
</script>
